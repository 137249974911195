<template>
  <div class="card card-body shadow-sm">

      <div class="card card-transparent">
        <div v-on:click="toggle = !toggle" class="toggle">検索
          <span v-if="toggle" class="fas fa-angle-up p-l-10"></span>
          <span v-else class="fas fa-angle-down p-l-10"></span>
        </div>
        <div v-show='toggle' class="card-header search-container">
          <validation-observer
            ref="entry"
            v-slot="{ passes, valid, validated }"
          >
             <form novalidate="novalidate" autocomplete="off" v-on:submit.prevent>
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 title-radio col-form-label">ステータス</label>
                  <div class="col-xl-6 col-lg-8 col-sm-9 gr-icheck">
                    <div class="icheck-primary d-inline app-inline m-l-20">
                      <input class="form-check-input" v-model="filters.status_booking" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="all">
                      <label for="inlineRadio1">全て</label>
                    </div>
                    <div class="icheck-primary d-inline app-inline m-l-20">
                      <input class="form-check-input" v-model="filters.status_booking" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="1">
                      <label for="inlineRadio2">予約済</label>
                    </div>
                    <div class="icheck-primary d-inline app-inline m-l-20">
                      <input class="form-check-input" v-model="filters.status_booking" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="2">
                      <label for="inlineRadio3">キャンセル</label>
                    </div>
                    <div class="icheck-primary d-inline app-inline m-l-20">
                      <input class="form-check-input" v-model="filters.status_booking" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="0">
                      <label for="inlineRadio4">予約ブロック</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-sm-4">
                    <div class="form-group">
                      <label>予約者名</label>
                      <input type="text" v-on:submit="filterTimeTable" v-model="filters.customer_name" class="full-width app-input">
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-sm-4">
                    <div class="form-group">
                      <label>マンション</label>
                      <app-select :name="filters.apartment_id"
                                  input-style="normal"
                                  v-model="filters.apartment_id"
                                  :options-data="meta.apartments"
                      />
                    </div>
                  </div>
                </div>
                <div class="m-t-10 row justify-content-center">
                  <a class="btn btn-default m-r-15" style="padding-top: 9px; border: 1px solid #9ba2aa;background-color: #f8f9fa;" @click="redirectDefault">リセット</a>
<!--                  <button class="btn btn-default m-r-15" v-on:click="redirectDefault">リセット</button>-->
                  <button class="btn btn-primary " id="abcdefasds" @click="filterTimeTable" :disabled="!valid && validated" >検索</button>
                </div>
             </form>
        </validation-observer>
        </div>
      </div><!-- end .top-search -->

      <div class="app-nav-table d-flex mb-3">
        <label>日付 <span class="app-require">＊</span></label>
        <div class="col-xl-4 col-lg-4 col-sm-4">
          <app-date-range-picker
            v-model="filters.date"
            timePicker24Hour="false"
            format="YYYY-MM-DD"
            @input="filters.date = $event"
            rules="required"
            name="date"
            @change="handleSearch"
          />
        </div>

        <div class="export-options-container ml-auto">
          <button class="btn btn-warning" @click="handleBtnCreateClick"  >新規作成</button>
        </div>
      </div>

    <div class="time-schedule-page full-height">
      <div class="time-schedule current_date" id="show-schedule"></div>
    </div>
  </div>
</template>
<script>
  import {ADMIN_URL_API} from "../../../constants/api";
  import { store } from '@store'
  export default {
    name: "ListAllAccount",
    data() {
      return {
        data : {},
        filters : {
          status_booking : this.$route.query.status_booking ,
          customer_name : this.$route.query.customer_name,
          apartment_id : this.$route.query.apartment_id,
          date : this.$route.query.date
        },
        meta : {
          apartments : []
        },
        toggle: false
      };
    },
    watch : {
      "$route.query": {
        handler: async function (after, before) {
          await this.getSchedule()
          this.filters = {
            status_booking : this.$route.query.status_booking ,
            customer_name : this.$route.query.customer_name,
            apartment_id : this.$route.query.apartment_id,
            date : this.$route.query.date
          }
        },
        deep: true,
      },
      "filters.date" : async function () {
       await this.$refs.entry.validate()
      }
    },
    created: async function () {
      this.getParamsFilters();
      await this.getSchedule()
    },
    methods: {
      async getSchedule() {
        await this.$request.get(this.ENDPOINT.BOOKING_SCHEDULE_LIST, this.filters).then(res => {
          this.data = res.data.data;
          let vm = this
          $("#show-schedule").empty();
          jQuery(document).ready(function(){
            var $sc = jQuery("#show-schedule").timeSchedule({
              startTime: "00:00", // schedule start time(HH:ii)
              endTime: "24:00",   // schedule end time(HH:ii)
              widthTime:60 * 5,  // cell timestamp example 10 minutes
              timeLineY:60,       // height(px)
              // verticalScrollbar:20,   // scrollbar (px)
              timeLineBorder:2,   // border(top and bottom)
              debug:"#debug",     // debug string output elements
              rows : vm.data,
              dataWidth : 300,
              widthTimeX : 15,
              // resizable : true,
              options : {
                admin_url : ADMIN_URL_API,
                token :   store.state.auth.currentAuth.token,
                date_filter: vm.filters.date
              },
              onChange: function(node,data){

              },

              init_data: function(node,data){
              },
              onClick : function(node, data) {
                if (data.event_id) {
                  vm.handleEventClick(data);
                }
              },
              click: function(node,data){
                alert("click event");
              },
              time_click: function(time,data){
              },
              onScheduleClick: function(node, time, timeline, data){
                vm.handleBtnCreateClickFromTimeSchedule(data, time)
              }
            });

            $(".apartments").each(function (item, element) {
              $(element).on('click', function () {
                let apartmentId = $(this).data("id");
                let routeApartment = vm.$router.resolve({name: vm.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: apartmentId}});
                window.open(routeApartment.href, '_blank');
              })
            })
            $(".facilities").each(function (item, element) {
              $(element).on('click', function () {
                let facilityId = $(this).data("id");
                let routeFacility = vm.$router.resolve({name: vm.ROUTES.ADMIN.FACILITY_EDIT, params: {id: facilityId}});
                window.open(routeFacility.href, '_blank');
              })
            })

            $(".notHoverItem").css({'cursor': 'inherit'})

            $.each($('.facilities'), (index, el) => {
              if (el.offsetWidth < el.scrollWidth) {
                $(el).tooltip({boundary: 'window'})
              }
            })

            $.each($('.apartments'), (index, el) => {
              if (el.offsetWidth < el.scrollWidth) {
                $(el).tooltip({boundary: 'window'})
              }
            })
            $.each($('.notDraggable'), (index, el) => {
              $(el).draggable('disable');
            })
            $.each($('.allowResizable'), (index, element) => {
              $(element).resizable('enable');
            })
          });
        })
      },
      getParamsFilters() {
        this.$request.get(this.ENDPOINT.UI_HELPER_FACILITIES).then(res => {
          this.meta = {
            apartments: res.data.apartments,
          }
          this.meta.apartments.unshift({
            id: "",
            name: ""
          })
        })
      },
      async filterTimeTable() {
       await this.$router.push({name: this.$route.name,
          query: this.filters
        })
      },
      async redirectDefault() {
        this.filters = {
          status_booking : 'all',
          date : moment().format('YYYY-MM-DD'),
          apartment_id : '',
          customer_name : ''
        }
        await this.$router.push({name: this.$route.name,
          query: this.filters
        })
      },
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.RESERVATION_CREATE})
      },
      handleBtnCreateClickFromTimeSchedule(data, time) {
        let start_time = time.replace(':','');
        this.filters = {
          date: this.$route.query.date,
          apartment_id: data.apartment_id,
          facility_id: data.facility_id,
          plan_id: data.last_plan,
          start_time: start_time
        }
        this.$router.push({
          name: this.ROUTES.ADMIN.RESERVATION_CREATE,
          query: this.filters
        })
      },
      handleEventClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.RESERVATION_EDIT, params: {id: entry.event_id}, query: {date: this.$route.query.date}})
      },
      async handleSearch() {
        this.getParamsFilters();
        await this.getSchedule()
      }
    },
  };

</script>


<style>
  .apartments {
    color : blue;
  }
  .facilities {
    color: blue !important;
  }
  .apartments:hover {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
  .facilities:hover {
    color: blue !important;
    cursor: pointer;
    text-decoration: underline;
  }
  .current_time_line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background: red;
    z-index : 2000
  }
  .even_hours {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    background: #626060;
    z-index : 2000
  }

  .sc_wrapper {
    display: flex;
  }
  .jq-schedule .sc_data {
    z-index: 999;
    background-color: #fff;
    border-right: 1px solid #bbb;

  }
  .jq-schedule .sc_data .timeline {
    border-right: 1px solid #bbb;
  }
  .jq-schedule .sc_menu .sc_header .sc_time:first-child {
    border-left: none;
    margin-left: -1px;
  }
  .jq-schedule .sc_menu .sc_header {
    overflow: hidden;
  }
  .jq-schedule .sc_menu {
    display : flex;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .search-container .error {
    position: absolute;
  }
  .jq-schedule .sc_menu .sc_header_cell {
    z-index: 9999;
    border-right: 1px solid #bbb;
  }
  .sc_bar.notDraggable {
    opacity: 1;
  }
  .sc_bar {
    border-bottom: 1px solid #bbb;
    border-right: 1px solid #bbb;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .jq-schedule .sc_menu .sc_header_cell {
      width: 53.5% !important
    }
    .jq-schedule .sc_menu .sc_header {
      width: 46% !important
    }
    /*.jq-schedule .sc_menu .sc_header .sc_time {*/
    /*  width: 2% !important;*/
    /*}*/
    .jq-schedule .sc_data .timeline .timeline-title {
      width: 28% !important
    }
    .jq-schedule .sc_data .timeline .timeline-subtitle {
      width: 28% !important
    }
    /*.jq-schedule .sc_main_scroll .sc_main .tl {*/
    /*  width: 7px !important;*/
    /*}*/
    .jq-schedule .sc_main_box  {
      width: 260px !important
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .jq-schedule .sc_menu .sc_header_cell {
      z-index: 998 !important;
    }
  }
  .toggle {
    height: 40px;
    text-align: center;
    padding: 10px;
    background-color: #343a40;
    color: #fff;
    cursor: pointer;
  }
</style>
